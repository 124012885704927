<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_heading">
					<h2>Hoe leden deze club hebben gevonden</h2>
					<!-- <infoDropdown>
                        Deze grafiek is niet gekoppeld aan de datumpikker hierboven.
                    </infoDropdown> -->
				</div>
				<div class="pg_header_description">
					<p>Een overzicht hoe de leden bij deze club terecht zijn gekomen</p>
				</div>
			</header>
			<highcharts v-if="!loading" :options="chartOptions"></highcharts>
			<hr />
		</div>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	name: "HowTheyFoundUs",
	components: {
		Loader,
	},
	data() {
		return {
			chartData: [
				{
					naam: "Vrienden",
					huidige: 300,
					vorige: 200,
				},
				{
					naam: "Facebook",
					huidige: 1400,
					vorige: 800,
				},
				{
					naam: "Instagram",
					huidige: 850,
					vorige: 700,
				},
				{
					naam: "Zoekmachine",
					huidige: 1000,
					vorige: 1200,
				},
				{
					naam: "Anders",
					huidige: 900,
					vorige: 1000,
				},
			],
			loading: false,
			chartOptions: {
				chart: {
					marginTop: 60,
					type: "column",
				},
				legend: {
					enabled: true,
				},
				xAxis: {
					categories: [],
					title: {
						text: "Mogelijkheden",
					},
					startOnTick: false,
					showFirstLabel: true,
					crosshair: {
						width: 0,
						color: "transparent",
					},
				},
				yAxis: {
					title: {
						text: "Totaal aantal leden",
					},
				},
				tooltip: {
					shared: false,
					formatter() {
						return `<b>${this.y}</b> totaal leden zijn hier terecht gekomen via ${this.x}`;
					},
				},
				series: [
					{
						name: "Vorige 28 dagen",
						data: [],
						color: "rgba(38, 35, 97, 1)",
					},
					{
						name: "Laatste 28 dagen",
						data: [],
						color: "rgb(212, 20, 90)",
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	methods: {
		...mapActions("lidmaatschappen", ["getReferalStatistics"]),
		async load() {
			// try {
			//     this.loading = true;
			//     await this.getGraphData({
			//         ...this.query,
			//         soort: 'club-verwijzing-vrienden',
			//         // periode: 'week',
			//         // vergelijkMet: 'vorige-periode',
			//         chartOptions: this.chartOptions,
			//     });
			//     this.loading = false;
			// } catch (error) {
			//     logger.error(error);
			// }

			// // CHECK-INS PER UUR
			try {
				this.loading = true;

				const request = {
					vrienden: await this.getReferalStatistics({
						...this.query,
						type: "club-verwijzing-vrienden",
					}),
					facebook: await this.getReferalStatistics({
						...this.query,
						type: "club-verwijzing-facebook",
					}),
					instagram: await this.getReferalStatistics({
						...this.query,
						type: "club-verwijzing-instagram",
					}),
					zoekmachine: await this.getReferalStatistics({
						...this.query,
						type: "club-verwijzing-zoekmachine",
					}),
					anders: await this.getReferalStatistics({
						...this.query,
						type: "club-verwijzing-anders",
					}),
				};

				this.chartData.forEach((data, index) => {
					const name = data.naam.toLowerCase();
					const requestData = request[name];
					if (!requestData) {
						return;
					}

					let huidige = 0;
					let vorige = 0;

					requestData.huidige.forEach((huidig) => {
						huidige += huidig.waarde;
					});
					requestData.vorige.forEach((vorig) => {
						vorige += vorig.waarde;
					});

					this.$set(this.chartOptions.xAxis.categories, index, data.naam);
					this.$set(this.chartOptions.series[0].data, index, huidige);
					this.$set(this.chartOptions.series[1].data, index, vorige);

					if (this.query.periodType === "variabel") {
						this.$set(this.chartOptions.series[0], "name", `Vorige ${this.query.days} dagen`);
						this.$set(this.chartOptions.series[1], "name", `Afgelopen ${this.query.days} dagen`);
					} else {
						this.$set(this.chartOptions.series[0], "name", `Vorig ${this.query.periode}`);
						this.$set(this.chartOptions.series[1], "name", `Afgelopen ${this.query.periode}`);
					}
				});

				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 42px);
	left: calc(100% - 42px);
}
</style>
