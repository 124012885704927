<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_top">
					<div class="pg_header_top_left">
						<div class="pg_header_heading">
							<h2>Aanmeldingen</h2>
						</div>
						<div class="pg_header_description">
							<p>Aanmeldingen van deze kalendermaand en dit jaar ten opzichte van de targets.</p>
						</div>
					</div>
				</div>
			</header>
			<highcharts :options="monthChartOptions" style="margin-bottom: -40px"></highcharts>
			<highcharts :options="yearChartOptions" style="margin-bottom: -40px"></highcharts>
			<hr />
		</div>
	</div>
</template>
<script>
import * as moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
	name: "RegistrationTargets",
	data() {
		return {
			loading: true,
			currentMonthlyRegistrations: 0,
			currentYearlyRegistrations: 0,
			defaultChartOptions: {
				chart: {
					type: "bar",
					marginTop: 0,
					height: 188,
					marginLeft: 0,
					spacingLeft: 0,
					marginRight: 0,
					spacingRight: 0,
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					title: {
						text: false,
					},
					crosshair: {
						width: 0,
						color: "transparent",
					},
					showFirstLabel: true,
					labels: {
						x: 10,
						align: "left",
						reserveSpace: false,
						style: {
							color: "#262261",
							whiteSpace: "nowrap",
						},
						useHTML: true,
					},
				},
				yAxis: {
					title: {
						text: false,
					},
					labels: {
						enabled: false,
					},
					gridLineColor: "transparent",
					min: 0,
					max: 100,
				},
				plotOptions: {
					series: {
						dataLabels: {
							color: "#262261",
							enabled: true,
							x: 10,
							style: {
								fontSize: "13px",
								fontWeight: "normal",
							},
							formatter() {
								return this.point.t;
							},
						},
						pointPadding: 0,
						groupPadding: 0.05,
					},
				},
				tooltip: {
					enabled: false,
				},
				series: [],
			},
		};
	},
	computed: {
		...mapState("branches", ["selected"]),
		...mapState("statistieken", ["query"]),
		vestigingWithId() {
			return this.$store.getters["vestiging/getVestigingByName"](this.selected.vestigingNaam);
		},
		locationTargets() {
			return this.$store.getters["settings/getRegistrationTargetsByLocation"](
				this.vestigingWithId.id,
			);
		},
		yearlyLocationTarget() {
			return this.$store.getters["settings/getYearlyRegistrationTargetByLocation"](
				this.vestigingWithId.id,
			);
		},
		targets() {
			if (this.loading) {
				return {
					monthlyRegistrationTarget: {
						current: 0,
						target: 0,
					},
					yearlyRegistrationTarget: {
						current: 0,
						target: 0,
					},
				};
			}

			const thisMonthsTarget = this.locationTargets.find(
				// Month numbers are 0 indexed in JavaScript, add 1 to get the right value from the data set.
				({ maand }) => maand === new Date().getMonth() + 1,
			);

			return {
				monthlyRegistrationTarget: {
					current: this.currentMonthlyRegistrations,
					target: thisMonthsTarget.waarde,
				},
				yearlyRegistrationTarget: {
					current: this.currentYearlyRegistrations,
					target: this.yearlyLocationTarget,
				},
			};
		},
		monthChartOptions() {
			const MIN_BAR_WIDTH = 20;
			const MAX_CURRENT_BAR_WIDTH = 75;
			const MAX_TARGET_BAR_WIDTH = 50;

			const currentHigherThanTarget =
				this.targets.monthlyRegistrationTarget.current >
				this.targets.monthlyRegistrationTarget.target;
			const maxValue = currentHigherThanTarget
				? this.targets.monthlyRegistrationTarget.current
				: this.targets.monthlyRegistrationTarget.target;

			let maxValueToCompareTo = maxValue;
			if (
				maxValue > this.targets.monthlyRegistrationTarget.target &&
				maxValue < this.targets.monthlyRegistrationTarget.target * 2
			) {
				maxValueToCompareTo = this.targets.monthlyRegistrationTarget.target * 2;
			}

			return {
				...this.defaultChartOptions,
				xAxis: {
					...this.defaultChartOptions.xAxis,
					categories: ["Aanmeldingen deze maand", "Target deze maand"],
				},
				series: [
					{
						data: [
							{
								t: this.targets.monthlyRegistrationTarget.current,
								y: this.calculateWidth(
									this.targets.monthlyRegistrationTarget.current,
									maxValueToCompareTo,
									MIN_BAR_WIDTH,
									// Only allow current value to be wider than target when it is higher than target.
									currentHigherThanTarget ? MAX_CURRENT_BAR_WIDTH : MAX_TARGET_BAR_WIDTH,
								),
								borderColor: "#d4145a",
								color: "rgba(212, 20, 90, 0.1)",
							},
							{
								t: this.targets.monthlyRegistrationTarget.target,
								y: this.calculateWidth(
									this.targets.monthlyRegistrationTarget.target,
									maxValue,
									MIN_BAR_WIDTH,
									MAX_TARGET_BAR_WIDTH,
								),
								borderColor: "#dadae3",
								color: "rgba(38, 35, 97, 0.05)",
							},
						],
					},
				],
			};
		},
		yearChartOptions() {
			const MIN_BAR_WIDTH = 20;
			const MAX_BAR_WIDTH = 90;

			const maxValue =
				this.targets.yearlyRegistrationTarget.current > this.targets.yearlyRegistrationTarget.target
					? this.targets.yearlyRegistrationTarget.current
					: this.targets.yearlyRegistrationTarget.target;
			return {
				...this.defaultChartOptions,
				xAxis: {
					...this.defaultChartOptions.xAxis,
					categories: ["Aanmeldingen dit jaar", "Target dit jaar"],
				},
				series: [
					{
						data: [
							{
								t: this.targets.yearlyRegistrationTarget.current,
								y: this.calculateWidth(
									this.targets.yearlyRegistrationTarget.current,
									maxValue,
									MIN_BAR_WIDTH,
									MAX_BAR_WIDTH,
								),
								borderColor: "#d4145a",
								color: "rgba(212, 20, 90, 0.1)",
							},
							{
								t: this.targets.yearlyRegistrationTarget.target,
								y: this.calculateWidth(
									this.targets.yearlyRegistrationTarget.target,
									maxValue,
									MIN_BAR_WIDTH,
									MAX_BAR_WIDTH,
								),
								borderColor: "#dadae3",
								color: "rgba(38, 35, 97, 0.05)",
							},
						],
					},
				],
			};
		},
	},
	watch: {
		query: {
			immediate: true,
			deep: true,
			handler() {
				this.getRegistrationTargets();
			},
		},
	},
	methods: {
		...mapActions("settings", ["getRegistrationTargetsByYear"]),
		...mapActions("statistieken", ["getByPeriod"]),
		...mapActions("vestiging", ["getVestigingen"]),
		calculateWidth(value, maxValue, minWidth, maxWidth) {
			return minWidth + (value / maxValue) * (maxWidth - minWidth);
		},
		async getRegistrationTargets() {
			this.loading = true;
			await this.getVestigingen();
			await this.getRegistrationTargetsByYear({
				year: new Date().getFullYear(),
			});
			const {
				huidige: { waarde: currentMonthlyRegistrations },
			} = await this.getByPeriod({
				...this.query,
				soort: "aantal-inschrijvingen",
				overWrittenPeriod: "maand",
				overWrittenPeriodType: "normal",
				overWrittenCompareToMomentObject: moment().startOf("month"),
			});
			const {
				huidige: { waarde: currentYearlyRegistrations },
			} = await this.getByPeriod({
				...this.query,
				soort: "aantal-inschrijvingen",
				overWrittenPeriod: "jaar",
				overWrittenPeriodType: "normal",
				overWrittenCompareToMomentObject: moment().startOf("year"),
			});
			this.currentMonthlyRegistrations = currentMonthlyRegistrations;
			this.currentYearlyRegistrations = currentYearlyRegistrations;

			this.loading = false;
		},
	},
};
</script>
<style lang="scss" scoped></style>
