<template>
	<button
		class="pg_kpis_item"
		data-s-amount-item
		:class="[selected ? 's_is-active' : '']"
		@click="$emit('selected')"
	>
		<div class="pg_kpis_label">
			<div class="pg_kpis_label_text">{{ title }}</div>
			<div
				v-show="
					statistics.before !== 0 &&
					statistics.current !== 0 &&
					statistics.before !== '-' &&
					statistics.current !== '-'
				"
				class="pg_deviation a_margin-right-10"
				:class="[statistics.compare >= 0 ? 'v_positive' : 'v_negative']"
			>
				{{ statistics.compare | valueFormatter }}%
			</div>
		</div>
		<div class="pg_kpis_no">
			{{ getFormattedValue(statistics.current) }}
			<slot></slot>
		</div>
		<div class="pg_kpis_label-before">{{ kpiLabelBefore }}</div>
		<loader :loading="loading"></loader>
	</button>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { logger } from "@/logger";

export default {
	components: {
		Loader,
	},
	props: {
		soort: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		valueFormatter: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			statistics: {
				current: 0,
				compare: 0,
				before: 0,
			},
			loading: false,
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		...mapState("branches", { selectedBranch: "selected" }),
		kpiLabelBefore() {
			const period =
				this.query.vergelijkMet === "vorig-jaar"
					? "jaar"
					: this.query.periodType === "normal"
						? this.query.periode
						: `${this.query.days} dagen`;
			const previousText = period === "jaar" ? "vorig" : "vorige";
			return `${previousText} ${period}: ${this.getFormattedValue(this.statistics.before)}`;
		},
	},
	watch: {
		query: {
			handler() {
				this.fetchStatistics();
			},
			deep: true,
		},
		soort: {
			handler() {
				this.fetchStatistics();
			},
		},
	},
	mounted() {
		this.fetchStatistics();
	},
	methods: {
		...mapActions("statistieken", ["getByPeriod", "getCompareByPeriod"]),
		async fetchStatistics() {
			this.loading = true;

			await Promise.all([
				this.getByPeriod({ ...this.query, soort: this.soort }).then(
					(result) => {
						this.statistics.current = result.huidige.waarde;
						this.statistics.before = result.vorige.waarde;
						this.statistics.compare =
							this.statistics.current === this.statistics.before
								? 0
								: ((this.statistics.current - this.statistics.before) /
										Math.abs(this.statistics.before)) *
									100;
					},
					(this.statistics.current = "-"),
					(this.statistics.before = "-"),
				),
				// this.getCompareByPeriod({ ...this.query, soort: this.soort }).then((result) => {
				//     this.statistics.compare = result.overigeVestigingen.waarde;
				// }),
			]).catch(logger.error);
			this.loading = false;
		},
		getFormattedValue(value) {
			if (this.valueFormatter) {
				return this.valueFormatter(value);
			}
			return value;
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
}
</style>
