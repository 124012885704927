export default (isForCurrentPeriod, { periodType, days, period }) => {
	const comparisonPeriod = period === "dag" ? "week" : period;

	if (periodType === "variabel") {
		if (days === 7) {
			return `${isForCurrentPeriod ? "deze" : "vorige"} week`;
		} else {
			return `${isForCurrentPeriod ? "laatste" : "vorige"} ${days} dagen`;
		}
	} else {
		if (comparisonPeriod === "jaar") {
			return `${isForCurrentPeriod ? "dit" : "vorig"} ${comparisonPeriod}`;
		} else {
			return `${isForCurrentPeriod ? "deze" : "vorige"} ${comparisonPeriod}`;
		}
	}
};
