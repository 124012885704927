export default {
	baseOptionsComparisonChart: {
		chart: {
			height: 100,
			marginTop: 10,
			marginBottom: 10,
			marginLeft: 20,
			spacingTop: 0,
			spacingBottom: 0,
			type: "column",
		},
		legend: {
			enabled: false,
		},
		xAxis: {
			title: {
				text: false,
			},
			crosshair: {
				width: 0,
				color: "transparent",
			},
			labels: {
				enabled: false,
			},
		},
		yAxis: {
			title: {
				text: false,
			},
			min: -10,
			max: 10,
			tickInterval: 5,
			labels: {
				align: "center",
				x: -10,
				y: 5,
			},
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			series: {
				pointPadding: 0.08,
				groupPadding: 0.01,
			},
		},
	},
	baseOptionsPieChart: {
		chart: {
			marginTop: 0,
			marginBottom: 0,
			type: "pie",
		},
		colors: ["#262261", "#d4145a", "#7b7aa0", "#dadae3", "#edb7cc", "#f1cdda", "#f3f3f5"],
		legend: {
			layout: "vertical",
			useHTML: true,
			itemMarginTop: 0,
			itemMarginBottom: 2,
			itemStyle: {
				fontSize: "16px",
				display: "flex",
				alignItems: "center",
			},
			labelFormatter() {
				return `<span style="width: 84px; display: inline-block; font-size: 2.4em; font-weight: 200; margin-right: 30px;">${this.y}%</span> ${this.name}`;
			},
			x: -80,
			y: 70,
			width: 500,
			symbolPadding: 20,
			align: "left",
			verticalAlign: "middle",
		},
		plotOptions: {
			pie: {
				center: [100, 190],
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: false,
				},
				showInLegend: true,
			},
		},
	},
};
