<template>
	<div class="pg_content_container">
		<div class="pg_content_1-1">
			<header class="pg_header">
				<div class="pg_header_top">
					<div class="pg_header_top_left">
						<div class="pg_header_heading">
							<h2>Extra's</h2>
						</div>
						<div class="pg_header_description">
							<p>Aantal leden met extra's</p>
						</div>
					</div>
				</div>
			</header>
			<highcharts :options="chartOptions" style="margin-bottom: -40px"></highcharts>
			<hr />
		</div>
	</div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { logger } from "@/logger";

export default {
	name: "CancellationTargets",
	data() {
		return {
			loading: true,
			chartOptions: {
				chart: {
					type: "bar",
					marginTop: 0,
					height: 282,
					marginLeft: 0,
					spacingLeft: 0,
					marginRight: 0,
					spacingRight: 0,
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					title: {
						text: false,
					},
					crosshair: {
						width: 0,
						color: "transparent",
					},
					showFirstLabel: true,
					labels: {
						x: 10,
						align: "left",
						reserveSpace: false,
						style: {
							color: "#262261",
							whiteSpace: "nowrap",
						},
						useHTML: true,
					},
					categories: [],
				},
				yAxis: {
					title: {
						text: false,
					},
					labels: {
						enabled: false,
					},
					gridLineColor: "transparent",
					min: 0,
					max: 100,
				},
				plotOptions: {
					series: {
						dataLabels: {
							color: "#262261",
							enabled: true,
							x: 10,
							style: {
								fontSize: "13px",
								fontWeight: "normal",
							},
							formatter() {
								return this.point.t;
							},
						},
						pointPadding: 0,
						groupPadding: 0.05,
					},
				},
				tooltip: {
					enabled: false,
				},
				series: [
					{
						data: [],
					},
				],
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		query: {
			immediate: true,
			deep: true,
			async handler() {
				await this.loadExtras();
			},
		},
	},
	async mounted() {
		await this.loadExtras();
	},
	methods: {
		...mapActions("lidmaatschappen", ["getExtraShares"]),
		calculateWidth(value, maxValue, minWidth, maxWidth) {
			return minWidth + (value / maxValue) * (maxWidth - minWidth);
		},
		async loadExtras() {
			try {
				this.loading = true;

				const result = await this.getExtraShares();
				const MIN_BAR_WIDTH = 20;
				const MAX_BAR_WIDTH = 50;
				const options = this.chartOptions;

				let highestValue = 0;
				result.forEach((item) => {
					const value = Number(item.getal.toFixed(1));
					if (value > highestValue) {
						highestValue = value;
					}
				});

				result.forEach((item, index) => {
					let borderColor = "#d4145a";
					let color = "rgba(212, 20, 90, 0.1)";
					if (index % 3 === 1) {
						borderColor = "#dadae3";
						color = "rgba(38, 35, 97, 0.05)";
					} else if (index % 3 === 2) {
						borderColor = "#262261";
						color = "rgba(38, 34, 97, 0.2)";
					}

					options.xAxis.categories.push(item.naam);
					options.series[0].data[index] = {
						t: Number(item.getal.toFixed(1)),
						y: this.calculateWidth(
							Number(item.getal.toFixed(1)),
							highestValue,
							MIN_BAR_WIDTH,
							MAX_BAR_WIDTH,
						),
						borderColor,
						color,
					};
				});

				this.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
		async getCancellationTargets() {
			this.loading = true;
		},
	},
};
</script>
<style lang="scss" scoped></style>
