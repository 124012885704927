<template>
	<div>
		Filter:
		<select v-model="filter">
			<option v-for="{ value, label } in filters" :key="value" :value="value">
				{{ label }}
			</option>
		</select>
		<vue-scroll-table
			:data="data"
			:headers="tableData.headers"
			:limits="[10, 20, 30]"
			:has-scroll="true"
			:class="{ 's_no-data': !data.length }"
			:classes="tableStylingClasses"
			:translations="{ limit: 'per pagina' }"
		>
			<template slot="naam" slot-scope="{ data: link, row }">
				<a :href="`${config.coach.server}/gebruiker/${row.at(-1)}`" target="_blank">{{ link }}</a>
			</template>
		</vue-scroll-table>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import moment from "moment";
import VueScrollTable from "vue-scroll-table";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/elements/Loader.vue";
import { config } from "@/lib/config";

export default {
	name: "TabelGroepslessen",
	components: {
		Loader,
		VueScrollTable,
	},
	props: {
		limits: {
			type: Array,
			default: function defaultValue() {
				return [25, 50, 100];
			},
		},
	},
	data() {
		return {
			data: [],
			filter: "binnen-een-maand",
			loading: false,
			limit: 0,
			page: 1,
			filters: [
				{
					label: "Proef abonnementen",
					value: "proef-abonnementen",
				},
				{
					label: "Binnen een maand",
					value: "binnen-een-maand",
				},
				{
					label: "Binnen twee maanden",
					value: "binnen-twee-maanden",
				},
			],
			tableData: {
				headers: [
					{ text: "Naam", sortable: false, width: 160 },
					{ text: "Telefoon", sortable: false },
					{ text: "Email", sortable: false },
					{ text: "App", sortable: false },
					{ text: "Laatste Bezoek", sortable: false },
				],
			},
			tableStylingClasses: {
				tableContainer: ["pg_table", "pg_table_vst", "pg_table_subscriptions"],
				pagination: {
					container: ["pg_pagination"],
					limit: {
						container: ["pg_pagination_filters"],
					},
					links: {
						container: ["pg_pagination_links"],
					},
				},
				scroll: {
					table: ["pg_table"],
				},
				sticky: {
					container: ["pg_table-overlay_vst"],
					table: ["pg_table-overlay"],
				},
				sortButtons: {
					button: [
						"button",
						"v_transparent_body",
						"v_is-icon",
						"v_icon-size-16",
						"a_no-margin",
						"a_no-padding",
					],
				},
			},
			config,
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
		limitedData() {
			const limitedData = this.data.filter((row, i) => {
				if (i >= (this.page - 1) * this.limit && i < this.page * this.limit) return true;
				return false;
			});
			if (!this.limit) return this.data || [];
			return limitedData;
		},
		pages() {
			if (!this.data.length || !this.limit) return 0;
			const pages = Math.ceil(this.data.length / this.limit);
			return pages;
		},
	},
	watch: {
		async filter() {
			this.load();
		},
		limit() {
			this.page = 1;
		},
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	mounted() {
		if (this.limits.length) {
			this.limit = this.limits[0];
		}
	},
	methods: {
		...mapActions("lidmaatschappen", ["getAflopendeAbonnementen"]),
		async load() {
			this.loading = true;
			this.data = await this.getAflopendeAbonnementen(this.filter);
			this.data = this.data.map(
				({ voornaam, achternaam, telefoon, email, app, laatsteBezoek, id }) => {
					return [
						`${voornaam} ${achternaam}`,
						telefoon,
						email,
						app ? "Ja" : "Nee",
						moment(laatsteBezoek).format("DD MMMM YYYY H:mm"),
						id,
					];
				},
			);
			this.loading = false;
		},
		paginate(num) {
			this.page = num;
		},
	},
};
</script>

<style lang="scss">
$first-column-width: 160px;

.pg_table_subscriptions.vst_table-container {
	&:before {
		left: $first-column-width !important;
	}
}

.s_no-data {
	.vst_table-container {
		&:before,
		&:after {
			height: 100%;
		}
	}
}

.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: calc(100% - 42px);
}
</style>
