<template>
	<div>
		<!-- <period></period> -->
		<section class="pg_content">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h1 class="s_elem-has-icon-left a_fill_default">
								Lidmaatschappen
								<icon :icon-id="'icon_lidmaatschappen'"></icon>
							</h1>
						</div>
						<div class="pg_header_description">
							<p>De belangrijkste indicatoren van rondom onze lidmaatschappen.</p>
						</div>
					</header>

					<div class="pg_kpis a_margin-top-40 a_margin-bottom-20" data-s-amount="4">
						<statistics-badge-rect
							title="Aantal leden"
							soort="aantal-leden"
							:selected="selectedBadge == 'aantal-leden'"
							@selected="selectedBadge = 'aantal-leden'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Aanmeldingen"
							soort="aantal-inschrijvingen"
							:selected="selectedBadge == 'aantal-inschrijvingen'"
							@selected="selectedBadge = 'aantal-inschrijvingen'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Afmeldingen"
							soort="aantal-afmeldingen"
							:selected="selectedBadge == 'aantal-afmeldingen'"
							@selected="selectedBadge = 'aantal-afmeldingen'"
						></statistics-badge-rect>
						<statistics-badge-rect
							title="Proefmaanden"
							soort="aantal-proefmaanden"
							:selected="selectedBadge == 'aantal-proefmaanden'"
							@selected="selectedBadge = 'aantal-proefmaanden'"
						></statistics-badge-rect>
					</div>

					<visits-chart :type="selectedBadge"></visits-chart>

					<hr />
				</div>
			</div>
		</section>

		<registration-targets></registration-targets>

		<section class="pg_content a_margin-top-60">
			<cancellation-targets></cancellation-targets>
		</section>

		<section class="pg_content a_margin-top-40">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h2>Abonnementen verdeling</h2>

							<!-- <infoDropdown>
                                Vestibulum id ligula porta felis euismod semper.
              </infoDropdown>-->
						</div>
						<div class="pg_header_description">
							<p>Aantal leden per abonnementsvorm</p>
						</div>
					</header>

					<!-- <highcharts :options="optionsChartMemberships"></highcharts> -->
					<pie-chart
						type="???"
						:data="abonnementsvorm.data"
						:loading="abonnementsvorm.loading"
					></pie-chart>

					<hr />
				</div>
			</div>
		</section>

		<section class="pg_content a_margin-top-20">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h2>Arrangementen verdeling</h2>

							<!-- <infoDropdown>
                                Vestibulum id ligula porta felis euismod semper.
              </infoDropdown>-->
						</div>
						<div class="pg_header_description">
							<p>Aantal leden per arrangement</p>
						</div>
					</header>

					<!-- <highcharts :options="optionsChartMemberships"></highcharts> -->
					<pie-chart
						type="???"
						:data="arrangementen.data"
						:loading="arrangementen.loading"
					></pie-chart>

					<hr />
				</div>
			</div>
		</section>

		<extra-verdeling></extra-verdeling>

		<how-they-found-us />

		<!-- <section class="pg_content a_margin-top-40">
            <div class="pg_content_container">
                <div class="pg_content_1-1">

                    <header class="pg_header">
                        <div class="pg_header_heading">
                            <h2>Verloop proefabonnementen</h2>

                            <infoDropdown>
                                Vestibulum id ligula porta felis euismod semper.
                            </infoDropdown>

                        </div>
                        <div class="pg_header_description">
                            <p>Aantallen in nieuwe, aflopende en afgelopen proefmaanden</p>
                        </div>

                    </header>

                    <pie-chart type="???"></pie-chart>

                    <hr>

                </div>
             </div>
    </section>-->
		<section class="pg_content a_margin-top-40">
			<div class="pg_content_container">
				<div class="pg_content_1-1">
					<header class="pg_header">
						<div class="pg_header_heading">
							<h2>Aflopende Abonnementen</h2>
							<!-- <infoDropdown>Vestibulum id ligula porta felis euismod semper.</infoDropdown> -->
						</div>
						<div class="pg_header_description">
							<p>Leden en hun (contact)gegevens waarvan het abonnement bijna afloopt</p>
						</div>
					</header>
					<tabel-lidmaatschaapen :limits="[10, 20, 30]"></tabel-lidmaatschaapen>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import { mapActions, mapState } from "vuex";
import StatisticsBadgeRect from "@/components/elements/StatisticsBadgeRect.vue";
import TabelLidmaatschaapen from "@/components/elements/TabelLidmaatschaapen.vue";
import { logger } from "@/logger";
import CancellationTargets from "../KpiDashboard/partials/CancellationTargets.vue";
import RegistrationTargets from "../KpiDashboard/partials/RegistrationTargets.vue";
import ExtraVerdeling from "./partials/ExtraVerdeling.vue";
import HowTheyFoundUs from "./partials/HowTheyFoundUs.vue";
import PieChart from "./partials/PieChart.vue";
import VisitsChart from "./partials/VisitsChart.vue";

export default {
	name: "Lidmaatschappen",
	components: {
		StatisticsBadgeRect,
		VisitsChart,
		PieChart,
		TabelLidmaatschaapen,
		RegistrationTargets,
		CancellationTargets,
		ExtraVerdeling,
		HowTheyFoundUs,
	},
	data() {
		return {
			selectedBadge: "aantal-unieke-bezoeken",
			abonnementsvorm: {
				loading: true,
				data: {
					series: [
						{
							name: "Aantal",
							colorByPoint: true,
							size: "100%",
							innerSize: "60%",
							data: [],
						},
					],
				},
			},
			arrangementen: {
				loading: true,
				data: {
					series: [
						{
							name: "Aantal",
							colorByPoint: true,
							size: "100%",
							innerSize: "60%",
							data: [],
						},
					],
				},
			},
		};
	},
	computed: {
		...mapState("statistieken", ["query"]),
	},
	watch: {
		query: {
			deep: true,
			immediate: true,
			handler() {
				this.load();
			},
		},
	},
	async mounted() {
		await this.load();
	},
	methods: {
		...mapActions("lidmaatschappen", ["getShares", "getArrangementenShares"]),
		async load() {
			await this.loadAbonnementsvorm();
			await this.loadArrangementen();
		},
		async loadAbonnementsvorm() {
			// CHECK-INS PER UUR
			try {
				this.abonnementsvorm.loading = true;

				const result = await this.getShares();
				const options = this.abonnementsvorm.data;
				options.series[0].data = result.map((item) => ({
					name: item.naam,
					y: Number(item.getal.toFixed(1)),
				}));
				if (options.series[0].data.length === 0) {
					options.series[0].data = [{ name: "no data", y: 100 }];
				}

				this.abonnementsvorm.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
		async loadArrangementen() {
			try {
				this.arrangementen.loading = true;

				const result = await this.getArrangementenShares();
				const options = this.arrangementen.data;
				options.series[0].data = result.map((item) => ({
					name: item.naam,
					y: Number(item.getal.toFixed(1)),
				}));
				if (options.series[0].data.length === 0) {
					options.series[0].data = [{ name: "no data", y: 100 }];
				}

				this.arrangementen.loading = false;
			} catch (error) {
				logger.error(error);
			}
		},
	},
};
</script>

<style lang="scss">
.highcharts-pie-series {
	.highcharts-point {
		y: 16px !important;
	}
}
</style>
