<template>
	<div>
		<highcharts :height="chartOptions.height" :options="chartOptions"></highcharts>
		<loader :loading="loading"></loader>
	</div>
</template>

<script>
import Loader from "@/components/elements/Loader.vue";
import ChartHelper from "@/services/ChartHelper";

export default {
	components: {
		Loader,
	},
	props: {
		type: {
			type: String,
			default: "",
		},
		data: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		chartOptions() {
			return Object.assign(Object.assign({}, ChartHelper.baseOptionsPieChart), this.data);
		},
	},
};
</script>

<style lang="scss" scoped>
.pg_loader {
	width: 100%;
	height: 100%;
	position: absolute;
	top: calc(100% - 36px - 6px);
	left: 100%;
}
</style>
